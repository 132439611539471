@import "@esders/theme/scss/variablen";

.mobil-icon{
   position: relative;
   top: 4px;
}

.icon {
	display: flex !important;
	align-items: center;
}

.show-mobile {
	display: none;

	@media #{$medium-and-down} {
		display: block;
	}
}

// app-help-dialog {
// 	position: fixed;
// 	height: 550px;
// 	width: 500px;
// 	right: 10px;
// 	bottom: 10px;
// 	z-index: 9999;
// }